// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "user-service\/register-intent-success": {
        "original_route": "fr\/user-service\/register-intent-success",
        "route": "fr\/user-service\/register-intent-success",
        "params": []
    },
    "keycloak\/contains": {
        "original_route": "fr\/keycloak\/contains",
        "route": "fr\/keycloak\/contains",
        "params": []
    },
    "user\/account\/request-password-reset-for-social-network": {
        "original_route": "fr\/social-network-access\/<socialNetwork>",
        "route": "fr\/social-network-access\/:socialNetwork",
        "params": [
            "socialNetwork"
        ]
    },
    "site\/error": {
        "original_route": "fr\/error",
        "route": "fr\/error",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET fr\/stationnement-regule\/contravention\/<idSERFine:\\w+>\/details",
        "route": "fr\/stationnement-regule\/contravention\/:idSERFine\/details",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST fr\/utilisateur",
        "route": "fr\/utilisateur",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT fr\/utilisateur\/<id:\\d+>",
        "route": "fr\/utilisateur\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT fr\/user-basic\/<id:\\d+>",
        "route": "fr\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "fr\/deconnecter",
        "route": "fr\/deconnecter",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT fr\/desinscription-utilisateur",
        "route": "fr\/desinscription-utilisateur",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "fr\/desinscription-utilisateur\/<token>",
        "route": "fr\/desinscription-utilisateur\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "fr\/mon-compte",
        "route": "fr\/mon-compte",
        "params": []
    },
    "account\/user": {
        "original_route": "fr\/my-details",
        "route": "fr\/my-details",
        "params": []
    },
    "account\/security": {
        "original_route": "fr\/securite",
        "route": "fr\/securite",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "fr\/account\/social-login\/<social_network_name>",
        "route": "fr\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "fr\/mes-vehicules",
        "route": "fr\/mes-vehicules",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "fr\/mes-vehicules\/<page:\\d+>",
        "route": "fr\/mes-vehicules\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "fr\/mes-remorques",
        "route": "fr\/mes-remorques",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "fr\/mes-remorques\/<page:\\d+>",
        "route": "fr\/mes-remorques\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST fr\/recuperer-mot-de-passe",
        "route": "fr\/recuperer-mot-de-passe",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "fr\/recuperer-mot-de-passe",
        "route": "fr\/recuperer-mot-de-passe",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST fr\/modifier-mot-de-passe\/<token>",
        "route": "fr\/modifier-mot-de-passe\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "fr\/modifier-mot-de-passe\/<token>",
        "route": "fr\/modifier-mot-de-passe\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "fr\/demander-modification-email",
        "route": "fr\/demander-modification-email",
        "params": []
    },
    "account\/update-email": {
        "original_route": "fr\/modifier-email\/<token>\/<newEmail>",
        "route": "fr\/modifier-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "fr\/changer-email-confirmer\/<code>",
        "route": "fr\/changer-email-confirmer\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "fr\/connecter",
        "route": "fr\/connecter",
        "params": []
    },
    "account\/end-process": {
        "original_route": "fr\/end-process",
        "route": "fr\/end-process",
        "params": []
    },
    "account\/register": {
        "original_route": "fr\/inscription",
        "route": "fr\/inscription",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "fr\/modifier-mot-de-passe",
        "route": "fr\/modifier-mot-de-passe",
        "params": []
    },
    "site\/terms": {
        "original_route": "fr\/conditions-legales",
        "route": "fr\/conditions-legales",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "fr\/politique-confidentialite-cookies",
        "route": "fr\/politique-confidentialite-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "fr\/transparence",
        "route": "fr\/transparence",
        "params": []
    },
    "site\/security-policy": {
        "original_route": "fr\/politique-securite",
        "route": "fr\/politique-securite",
        "params": []
    },
    "account\/validate": {
        "original_route": "fr\/confirmer\/<token>",
        "route": "fr\/confirmer\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "fr\/commande\/badge-telepeage\/<id:\\d+>",
        "route": "fr\/commande\/badge-telepeage\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "fr\/commandes",
        "route": "fr\/commandes",
        "params": []
    },
    "order\/page": {
        "original_route": "fr\/commandes\/<page:\\d+>",
        "route": "fr\/commandes\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "fr\/commandes\/badge-telepeage",
        "route": "fr\/commandes\/badge-telepeage",
        "params": []
    },
    "mobe\/page": {
        "original_route": "fr\/commandes\/badge-telepeage\/<page:\\d+>",
        "route": "fr\/commandes\/badge-telepeage\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "fr\/commanda\/transits",
        "route": "fr\/commanda\/transits",
        "params": []
    },
    "toll\/page": {
        "original_route": "fr\/commanda\/transits\/<page:\\d+>",
        "route": "fr\/commanda\/transits\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT fr\/mobe\/<id:\\w+>\/vehicle\/<vehicleId:\\d+>",
        "route": "fr\/mobe\/:id\/vehicle\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT fr\/mobe\/<id:\\w+>\/supprimer-vehicule",
        "route": "fr\/mobe\/:id\/supprimer-vehicule",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT fr\/mobe\/<id:\\w*>\/annulation",
        "route": "fr\/mobe\/:id\/annulation",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET fr\/mobe\/buscar",
        "route": "fr\/mobe\/buscar",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT fr\/mobe\/<idMobe:\\w+>\/wallet\/<idWallet:\\d+>",
        "route": "fr\/mobe\/:idMobe\/wallet\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET fr\/paiement-mobile-stationnement-regule",
        "route": "fr\/paiement-mobile-stationnement-regule",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET fr\/ticket\/<idSERTicket:\\w+>\/details",
        "route": "fr\/ticket\/:idSERTicket\/details",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET fr\/ticket\/<idSERTicket:\\w+>\/envoyer-email",
        "route": "fr\/ticket\/:idSERTicket\/envoyer-email",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET fr\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "fr\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "fr\/stationner-a-<slug>\/emettre-ticket",
        "route": "fr\/stationner-a-:slug\/emettre-ticket",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST fr\/tickets-stationnement-regule\/emettre-ticket",
        "route": "fr\/tickets-stationnement-regule\/emettre-ticket",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST fr\/ticket\/<id>\/repartir",
        "route": "fr\/ticket\/:id\/repartir",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET fr\/ticket\/<id>\/repartir",
        "route": "fr\/ticket\/:id\/repartir",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "fr\/ticket\/<id>\/nouveau",
        "route": "fr\/ticket\/:id\/nouveau",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "fr\/porte-monnaie\/mode-de-paiement",
        "route": "fr\/porte-monnaie\/mode-de-paiement",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "fr\/porte-monnaie",
        "route": "fr\/porte-monnaie",
        "params": []
    },
    "wallet\/show": {
        "original_route": "fr\/porte-monnaie\/<idWallet:\\d+>\/afficher",
        "route": "fr\/porte-monnaie\/:idWallet\/afficher",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "fr\/porte-monnaie\/<idWallet:\\d+>\/donnees",
        "route": "fr\/porte-monnaie\/:idWallet\/donnees",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "fr\/porte-monnaie\/<idWallet:\\d+>\/beneficiaires",
        "route": "fr\/porte-monnaie\/:idWallet\/beneficiaires",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "fr\/porte-monnaies",
        "route": "fr\/porte-monnaies",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT fr\/porte-monnaie\/solder-debit",
        "route": "fr\/porte-monnaie\/solder-debit",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST fr\/porte-monnaie\/recharge",
        "route": "fr\/porte-monnaie\/recharge",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST fr\/porte-monnaie\/confirm-payment-intent",
        "route": "fr\/porte-monnaie\/confirm-payment-intent",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST fr\/eu\/porte-monnaie\/<idWallet:\\d+>\/carte-associee\/<idCreditCard:\\d+>",
        "route": "fr\/eu\/porte-monnaie\/:idWallet\/carte-associee\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "invoice\/pdf": {
        "original_route": "GET fr\/facturation\/factures\/<invoiceUid>.pdf",
        "route": "fr\/facturation\/factures\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET fr\/facturation\/factures",
        "route": "fr\/facturation\/factures",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET fr\/facturation\/factures\/<page:\\d+>",
        "route": "fr\/facturation\/factures\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT fr\/facturation\/donnees",
        "route": "fr\/facturation\/donnees",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET fr\/rechercher-parking",
        "route": "fr\/rechercher-parking",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET fr\/rechercher-parking-aeroport",
        "route": "fr\/rechercher-parking-aeroport",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET fr\/rechercher-abonnement",
        "route": "fr\/rechercher-abonnement",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST fr\/rechercher-abonnement",
        "route": "fr\/rechercher-abonnement",
        "params": []
    },
    "booking\/process": {
        "original_route": "GET fr\/confirmer-reservation\/<idPrebooking:\\d+>",
        "route": "fr\/confirmer-reservation\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET fr\/confirmer-reservation-iat",
        "route": "fr\/confirmer-reservation-iat",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET fr\/guantera\/actuales",
        "route": "fr\/guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET fr\/guantera\/pasados\/<page:\\d+>",
        "route": "fr\/guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/show": {
        "original_route": "GET fr\/reservation\/<idBooking:\\d+>\/afficher",
        "route": "fr\/reservation\/:idBooking\/afficher",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET fr\/reservation\/<idBooking:\\d+>\/nouvelle",
        "route": "fr\/reservation\/:idBooking\/nouvelle",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT fr\/reservation\/annuler\/<idBooking:\\d+>",
        "route": "fr\/reservation\/annuler\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET fr\/vehicule-marque\/<brandId:\\d+>\/modeles",
        "route": "fr\/vehicule-marque\/:brandId\/modeles",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET fr\/vehicule",
        "route": "fr\/vehicule",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET fr\/vehicule\/<vehicleid:\\d+>",
        "route": "fr\/vehicule\/:vehicleid",
        "params": [
            "vehicleid"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE fr\/vehicule\/<vehicleId:\\d+>",
        "route": "fr\/vehicule\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST fr\/vehicule",
        "route": "fr\/vehicule",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT fr\/vehicule\/<vehicleId:\\d+>",
        "route": "fr\/vehicule\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET fr\/abonnement\/<seasonTicketId:\\d+>\/souscrire",
        "route": "fr\/abonnement\/:seasonTicketId\/souscrire",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET fr\/contrats\/abonnement\/<id:\\d+>\/details",
        "route": "fr\/contrats\/abonnement\/:id\/details",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET fr\/contrats\/abonnement\/<id:\\d+>\/afficher",
        "route": "fr\/contrats\/abonnement\/:id\/afficher",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET fr\/contrats\/abonnement\/<id:\\d+>\/nouveau",
        "route": "fr\/contrats\/abonnement\/:id\/nouveau",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST fr\/abonnement",
        "route": "fr\/abonnement",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT fr\/abonnement\/<contractId:\\d+>\/vehicule\/<vehicleId:\\d+>",
        "route": "fr\/abonnement\/:contractId\/vehicule\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "api\/season-ticket-contract\/cancel": {
        "original_route": "DELETE fr\/abonnement\/<idContract:\\d+>",
        "route": "fr\/abonnement\/:idContract",
        "params": [
            "idContract"
        ]
    },
    "site\/operators": {
        "original_route": "fr\/operateurs",
        "route": "fr\/operateurs",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET fr\/preferences",
        "route": "fr\/preferences",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT fr\/preferences\/<id:\\d+>",
        "route": "fr\/preferences\/:id",
        "params": [
            "id"
        ]
    },
    "wallet-preferences\/index": {
        "original_route": "GET fr\/preferences-porte-monnaie",
        "route": "fr\/preferences-porte-monnaie",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT fr\/preferences-porte-monnaie\/<id:\\d+>",
        "route": "fr\/preferences-porte-monnaie\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET fr\/confidentialite",
        "route": "fr\/confidentialite",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT fr\/confidentialite",
        "route": "fr\/confidentialite",
        "params": []
    },
    "glovie\/fines": {
        "original_route": "GET fr\/annuler-contravention",
        "route": "fr\/annuler-contravention",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST fr\/contravention\/verifier",
        "route": "fr\/contravention\/verifier",
        "params": []
    },
    "airport\/show": {
        "original_route": "GET fr\/stationner-a-laeroport-<slug>\/<id:\\d+>",
        "route": "fr\/stationner-a-laeroport-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "GET fr\/stationner-a-<slug>",
        "route": "fr\/stationner-a-:slug",
        "params": [
            "slug"
        ]
    },
    "shop\/shop\/invoice": {
        "original_route": "fr\/facture\/<uid>.pdf",
        "route": "fr\/facture\/:uid.pdf",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "fr\/desinscription-email-commercial\/<email>",
        "route": "fr\/desinscription-email-commercial\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "fr\/boutique\/produits",
        "route": "fr\/boutique\/produits",
        "params": []
    },
    "viat-shop\/detail": {
        "original_route": "fr\/boutique\/commande\/<id>",
        "route": "fr\/boutique\/commande\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/shop": {
        "original_route": "GET fr\/boutique\/pour-confirmer-la-commande\/<id>",
        "route": "fr\/boutique\/pour-confirmer-la-commande\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET fr\/boutique\/commande\/<id>\/nouveau",
        "route": "fr\/boutique\/commande\/:id\/nouveau",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "fr\/depot",
        "route": "fr\/depot",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "fr\/reservation-atelier\/<idBooking:\\d+>\/nouvelle",
        "route": "fr\/reservation-atelier\/:idBooking\/nouvelle",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "fr\/reserver-atelier\/<idProduct:\\d+>",
        "route": "fr\/reserver-atelier\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "fr\/confirmer-reservation-atelier\/<idBooking:\\d+>",
        "route": "fr\/confirmer-reservation-atelier\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "fr\/reservation-atelier\/<idWorkshopBooking:\\d+>\/afficher",
        "route": "fr\/reservation-atelier\/:idWorkshopBooking\/afficher",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landind-workshop": {
        "original_route": "fr\/atelier",
        "route": "fr\/atelier",
        "params": []
    },
    "site\/viat": {
        "original_route": "fr\/viat",
        "route": "fr\/viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "fr\/viat\/parkings",
        "route": "fr\/viat\/parkings",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "fr\/viat\/rabis",
        "route": "fr\/viat\/rabis",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "fr\/viat\/fonctionnement",
        "route": "fr\/viat\/fonctionnement",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "fr\/viat\/portugalgo-errepublika",
        "route": "fr\/viat\/portugalgo-errepublika",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "fr\/comme-parc",
        "route": "fr\/comme-parc",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "fr\/parking-aeroport",
        "route": "fr\/parking-aeroport",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "fr\/location-garage",
        "route": "fr\/location-garage",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "fr\/racheter-coupons",
        "route": "fr\/racheter-coupons",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "fr\/marchande",
        "route": "fr\/marchande",
        "params": []
    },
    "vehicle-inspection-station\/show": {
        "original_route": "fr\/itv\/<slug>\/<id:\\d+>",
        "route": "fr\/itv\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "fr\/itv",
        "route": "fr\/itv",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "fr\/rechercher-itv",
        "route": "fr\/rechercher-itv",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET fr\/reservation-itv\/<idBooking:\\d+>\/details",
        "route": "fr\/reservation-itv\/:idBooking\/details",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET fr\/rotation\/<idRotation:\\d+>\/details",
        "route": "fr\/rotation\/:idRotation\/details",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET fr\/reservation-itv\/<idBooking:\\d+>\/nouveau",
        "route": "fr\/reservation-itv\/:idBooking\/nouveau",
        "params": [
            "idBooking"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET fr\/zone\/<zoneId>\/vehicule\/<vehicleId:\\d+>",
        "route": "fr\/zone\/:zoneId\/vehicule\/:vehicleId",
        "params": [
            "zoneId",
            "vehicleId"
        ]
    },
    "parking\/show": {
        "original_route": "GET fr\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "fr\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/landing-enterprise": {
        "original_route": "fr\/entreprise",
        "route": "fr\/entreprise",
        "params": []
    },
    "site\/landing-electric-charge": {
        "original_route": "fr\/charge-electrique",
        "route": "fr\/charge-electrique",
        "params": []
    },
    "merchant\/index": {
        "original_route": "fr\/marchande\/compte",
        "route": "fr\/marchande\/compte",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "fr\/marchande\/boutique",
        "route": "fr\/marchande\/boutique",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "fr\/marchande\/boutique\/etape<id:\\d+>",
        "route": "fr\/marchande\/boutique\/etape:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "fr\/marchande\/commande\/<id:\\d+>.pdf",
        "route": "fr\/marchande\/commande\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "fr\/marchande\/commande\/<id:\\d+>\/detail",
        "route": "fr\/marchande\/commande\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "fr\/merchant\/sample-coupon",
        "route": "fr\/merchant\/sample-coupon",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET fr\/porte-monnaie\/list",
        "route": "fr\/porte-monnaie\/list",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT fr\/payer-reservation\/<idBooking:\\d+>\/porte-monnaie\/<idWallet:\\d+>",
        "route": "fr\/payer-reservation\/:idBooking\/porte-monnaie\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST fr\/payer-abonnement\/porte-monnaie\/<idWallet:\\d+>",
        "route": "fr\/payer-abonnement\/porte-monnaie\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST fr\/payer-ticket\/porte-monnaie\/<idWallet:\\d+>",
        "route": "fr\/payer-ticket\/porte-monnaie\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST fr\/payer-annuler\/porte-monnaie\/<idWallet:\\d+>",
        "route": "fr\/payer-annuler\/porte-monnaie\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "kiosk\/reset-password": {
        "original_route": "fr\/kiosque\/recuperer-mot-de-passe\/<token>",
        "route": "fr\/kiosque\/recuperer-mot-de-passe\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "fr\/on-boarding-ok",
        "route": "fr\/on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "fr\/stations-dessence",
        "route": "fr\/stations-dessence",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "fr\/parkings",
        "route": "fr\/parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "fr\/stationner-a-<townSlug>\/parking-<poiSlug>",
        "route": "fr\/stationner-a-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "fr\/evenements-a-<poiId>\/<month:\\d+>",
        "route": "fr\/evenements-a-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "fr\/itv\/reservation-itv-<poiSlug>",
        "route": "fr\/itv\/reservation-itv-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET fr\/parking\/<idParking:\\d+>\/bon-stationnement\/<id:\\d+>",
        "route": "fr\/parking\/:idParking\/bon-stationnement\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET fr\/bon-stationnement\/<id:\\d+>\/nouveau",
        "route": "fr\/bon-stationnement\/:id\/nouveau",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET fr\/bon-stationnement\/<id:\\d+>\/afficher",
        "route": "fr\/bon-stationnement\/:id\/afficher",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST fr\/masmovil\/refuse\/<token>\/ok",
        "route": "fr\/masmovil\/refuse\/:token\/ok",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "fr\/masmovil\/refuse\/<token>",
        "route": "fr\/masmovil\/refuse\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "fr\/rechercher-stations-charge-electrique",
        "route": "fr\/rechercher-stations-charge-electrique",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET fr\/reservation-stations-charge-electrique\/<idBooking:\\d+>\/nouveau",
        "route": "fr\/reservation-stations-charge-electrique\/:idBooking\/nouveau",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/landing-fines": {
        "original_route": "fr\/pilote-plus\/amendes-non-notifiees",
        "route": "fr\/pilote-plus\/amendes-non-notifiees",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "fr\/pilote-plus\/embaucher",
        "route": "fr\/pilote-plus\/embaucher",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "fr\/pilote-plus\/abonnements",
        "route": "fr\/pilote-plus\/abonnements",
        "params": []
    },
    "glovie\/driver-plus-fines": {
        "original_route": "fr\/pilote-plus\/amendes",
        "route": "fr\/pilote-plus\/amendes",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET fr\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "fr\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "fr\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "fr\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "fr\/louer-des-voitures",
        "route": "fr\/louer-des-voitures",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "fr\/terminer-location-voiture",
        "route": "fr\/terminer-location-voiture",
        "params": []
    },
    "rental-car\/detail": {
        "original_route": "fr\/louer-des-voitures\/<id:\\d+>\/detail",
        "route": "fr\/louer-des-voitures\/:id\/detail",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "fr\/louer-des-voitures\/chercher",
        "route": "fr\/louer-des-voitures\/chercher",
        "params": []
    },
    "rental-car\/un-born": {
        "original_route": "fr\/louer-des-voitures\/afficher\/<unbornId:\\d+>\/<uuid>",
        "route": "fr\/louer-des-voitures\/afficher\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "rental-car\/un-born-share": {
        "original_route": "fr\/louer-des-voitures\/share\/<unbornId:\\d+>\/<uuid>",
        "route": "fr\/louer-des-voitures\/share\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "site\/index": {
        "original_route": "fr",
        "route": "fr",
        "params": []
    },
    "esp\/sitemap\/lang": {
        "original_route": "fr\/sitemap.xml",
        "route": "fr\/sitemap.xml",
        "params": []
    },
    "site\/home-redirect": {
        "original_route": "esp\/fr",
        "route": "esp\/fr",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "fr\/profil-complet",
        "route": "fr\/profil-complet",
        "params": []
    }
};